<template>
  <div class="main">
    <h1 class="h1">{{$t('MyTravels')}}</h1>
    <div class="form-wrap">
      <div class="bd">
        <div class="group">
          <div class="item">
            <el-input type="text"
                      :placeholder="$t('Travel title')"
                      v-model.trim="title"
                      maxlength="100"
                      show-word-limit/>
          </div>
          <!--简介-->
          <div class="item">
            <el-input type="textarea"
                      :placeholder="$t('Introduction')"
                      v-model.trim="introduction"
                      maxlength="500"
                      show-word-limit/>
          </div>
        </div>
<!--        <div id="editor">-->
<!--          <froala :config="froalaConfig" v-model="content"/>-->
<!--        </div>-->
        <editorElem v-model="content"/>
        <div class="category cl">
          <checkbox class="fl" v-for="item of categoryOptions" v-model="item.isSelected"
                    :value="!!item.isSelected"
                    :key="item.id">
            <div class="checkbox-label">{{item.name}}</div>
          </checkbox>
        </div>
        <div class="keywords">
          <div class="keyword-item" v-for="(item,index) of keywords" :key="index">
            <input class="ipt"
                   type="text"
                   autocomplete="off"
                   :placeholder="$t('Customize')"
                   v-model="keywords[index]"
                   @keyup.enter="editKeyword(index,$event)"/>
            <a class="btn-del" href="javascript:;" @click="delKeyword(index)">
              <img src="../../assets/images/icon/close-circle.png"/>
            </a>
          </div>
        </div>
        <!--封面图片-->
        <div class="form-item cl">
          <div class="label fl">{{$t('Cover')}}：</div>
          <upload-cover class="fl" v-model="coverUrl"/>
        </div>
        <!--状态-->
        <div class="form-item cl">
          <div class="label fl">{{$t('Status')}}：</div>
          <checkbox v-model="isPublish">
            <div class="checkbox-label">{{$t('Release')}}</div>
          </checkbox>
        </div>
      </div>
      <div class="ft cl">
        <button class="button m-r" type="button" @click="$router.go(-1)">{{$t('Cancel')}}</button>
        <button class="button button-primary" type="button" @click="onSubmit">{{$t('Save')}}</button>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue';
  import VueFroala from 'vue-froala-wysiwyg';
  import Checkbox from '../../components/Checkbox';
  import UploadCover from '../../components/UploadCover';
  import editor from '../../mixins/editor';
  import {getDictList} from '../../api/common';
  import {saveTravels, getMytravelsDetail} from '../../api/travels';

  import editorElem from '../../components/EditorBar';
  Vue.use(VueFroala);
  export default {
    name: 'EditTravels',
    data() {
      return {
        language: '',
        travelsId: '',
        philosopherId: '',
        title: '',
        introduction: '',
        content: '',
        categoryOptions: [],
        keywords: [''],
        coverUrl: '',
        froalaConfig: {imageUploadURL: '/v1/api/common/file'},
        isPublish: false,
        editor: ''
      };
    },
    components: {UploadCover, Checkbox, editorElem},
    mixins: [editor],
    created() {
      this.language = this.$route.query.language || this.$i18n.locale;
      this.travelsId = this.$route.query.id || '';
      this.philosopherId = this.$route.query.philosopherId;
      getDictList('travelsCategory').then(res => {
        this.categoryOptions = res.value || [];
        this.getDetail();
      }).catch(() => {
        this.getDetail();
      });
    },
    methods: {
      // 获取游记详情
      getDetail() {
        if (this.travelsId) {
          getMytravelsDetail(this.language, this.travelsId).then(res => {
            const {title, introduction, content, coverUrl, keywords, isStatus} = res.value;
            this.title = title;
            this.introduction = introduction;
            this.content = content;
            this.coverUrl = coverUrl;
            this.keywords = keywords.filter(item => !item.categoryId).map(item => item.keyword).concat(this.keywords);
            this.isPublish = isStatus === 'release';
            for (let item of keywords) {
              if (item.categoryId) {
                for (let i in this.categoryOptions) {
                  if (this.categoryOptions[i].code === item.categoryId) {
                    this.categoryOptions[i].isSelected = true;
                    break;
                  }
                }
              }
            }
          });
        }
      },
      // 编辑关键词
      editKeyword(index, event) {
        const current = this.keywords[index];
        event.target.blur();
        if (this.keywords.length - 1 === index) {
          if (current) {
            this.keywords.push('');
          }
        } else {
          if (!current) {
            this.keywords.splice(index, 1);
          }
        }
      },
      // 删除关键词
      delKeyword(index) {
        this.keywords.splice(index, 1);
      },
      // 保存
      onSubmit() {
        const status = this.isPublish ? 'release' : 'edit';
        let categoryOptions = this.categoryOptions.filter(item => item.isSelected).map(item => ({
          categoryId: item.code,
          keyword: item.name
        }));
        let keywords = this.keywords.filter(item => !!item).map(item => ({categoryId: '', keyword: item}));
        let data = {
          philosopherId: this.philosopherId,
          coverUrl: this.coverUrl,
          isStatus: status,
          languageType: this.language,
          title: this.title,
          keywords: [...categoryOptions, ...keywords],
          introduction: this.introduction,
          content: this.content
        };

        if (Object.values(data).filter(item => !item).length) {
          this.$message.warning(this.$t('Please complete the information before submitting'));
          return false;
        }

        const loading = this.$loading({text: 'Loading'});
        data.travelsId = this.travelsId;
        saveTravels(data).then(res => {
          loading.close();
          this.$message.success(status === 'release' ? '提交成功' : this.$t('SaveSuccessfully'));
          this.$router.go(-1);
        }).catch(() => {
          loading.close();
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .form-wrap{
    margin-top:32px;
    .bd{}
    .ft{
      margin-top:40px;
      a:hover{opacity:0.8;}
    }
    .group{padding:0 20px;background-color:#f4f4f4;}
    .item{
      border-bottom:1px dashed #e0e0e0;
      &:last-child{border-bottom:none;}
    }
    /deep/ .el-input__inner{height:60px;padding:0 60px 0 0;border:none;background-color:transparent;}
    /deep/ .el-textarea__inner{height:168px;padding-left:0;padding-right:0;border:none;background-color:transparent;resize:none;}
    /deep/ .el-input__count,
    /deep/ .el-input__count-inner{background-color:transparent;}
  }
  .editor{margin-top:8px;}
  .category{
    margin-top:32px;font-size:16px;
    > .fl{margin-right:16px;}
  }
  .keywords{
    display:flex;flex-wrap:wrap;margin-bottom:32px;
    .keyword-item{
      position:relative;margin-top:16px;margin-right:8px;
      &:not(:last-child){
        &:hover{
          .btn-del{display:block;}
        }
      }
    }
    .ipt{
      width:160px;height:44px;padding:0 10px;text-align:center;color:#321908;font-size:18px;border:1px dashed #ccc;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .btn-del{display:none;position:absolute;top:11px;right:4px;}
  }
  .form-item{
    margin-top:32px;font-size:16px;
    .label{line-height:1;}
  }
</style>
